<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>备注情况说明</span>
          <el-button type="primary" @click="addData">新增</el-button>
        </div>
      </FloatTitBtn>
      <div class="edit-container">
        <PublicTable
          ref="publicTable"
          :tableHeaders2="tableHeaders2"
          @changePage="handlePageChange"
        >
          <template #td_CZ="scope">
            <el-button
              size="mini"
              type="text"
              v-show="isDisabled"
              @click="openNews(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              v-show="isDisabled"
              @click="delNews(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </PublicTable>
        <el-dialog v-model="dialogFormVisible" :title="dialogTitle" width="30%">
          <el-row>
            <el-col :span="24">
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="情况说明">
                  <el-input
                    type="textarea"
                    v-model.string="form.sm"
                    rows="5"
                  ></el-input>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取消</el-button>
              <el-button type="primary" @click="que">确认</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { ref } from 'vue';
// import dayjs from 'dayjs';
import { ElMessage, ElMessageBox } from "element-plus";
// import mgr from "@/services/security.js";
import FloatTitBtn from "../../components/FloatTitBtn.vue";
import PublicTable from "@/components/table/PublicTable";
// import { arrayExpression } from '@babel/types';
export default {
  name: "bzqksm",
  components: { FloatTitBtn, PublicTable },
  data() {
    return {
      // readOnly: true,
      // labelPosition: 'left',
      form: {
        id: "",
        bjid: "",
        smlx: 0,
        bzsf: true,
        sm: "",
        jbr: "",
        jbrxm: "",
        jbrq: new Date(),
      },
      // 弹框标题
      dialogTitle: "",
      // 弹框是否显示
      dialogFormVisible: false,
      // 按钮禁用
      isDisabled: true,
      queryId: "",
      tableHeaders2: [], //表头,
      para: {
        queryId: "",
        PageInfo: { pageNum: 1, pageSize: 10 },
        conditions: null,
      },
      resArr: [],
    };
  },
  methods: {
    whereconditions() {
      console.log("1111111111",this.$store);
      this.queryId = "bzqksm";
      let conditions = [
        {
          Key: "JBR",
          Value: "%" + this.$store.state.userInfo.UserId + "%",
          IsCondition: true,
          Operator: "like",
          LogicalOperator: "and",
        },
        {
          Key: "BJID",
          Value: this.$route.query.instanceId,
          IsCondition: true,
          Operator: "=",
          LogicalOperator: "and",
        },
      ];
      this.getLoadData(this.queryId, conditions);
    },
    //获取表头数据
    getLoadData(queryId, conditions) {
      this.para.queryId = this.queryId;
      if (conditions === []) conditions = null;
      this.para.conditions = conditions;
      let me = this;
      this.$ajax
        .get(
          this.$appConfig.apiUrls.tableApi +
            "/api/CommonTable/GetFileds?queryKey=" +
            queryId
        )
        .then(function (response) {
          if (response.data.isSuccess) {
            me.tableHeaders2 = response.data.data;
            console.log("打印获取的表头数据", me.tableHeaders2);
            me.getDataList(me.para);
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
    //获取表格数据
    async getDataList(para) {
      this.para = para;
      // console.log("biaogeshuju",this.para);
      let me = this;
      var res = await this.$ajax.post(
        this.$appConfig.apiUrls.tableApi + "/api/CommonTable/LoadData",
        JSON.stringify(para)
      );
      res = JSON.parse(res.data.data);
      console.log("获取表格数据123", res);
      me.$refs.publicTable.getTData(me.para, res);
    },
    // 分页
    handlePageChange(val) {
      this.para.PageInfo.pageNum = val;
      this.getDataList(this.para);
    },
    // 新增
    addData() {
      this.dialogFormVisible = true;
      // 修改弹框标题
      this.dialogTitle = "新增";
    },
    // 编辑
    openNews(index, row) {
      // this.form.bjid = this.$route.query.instanceId;
      // if(this.form.bjid==row.BJID){
      this.dialogFormVisible = true;
      // 修改弹框标题
      this.dialogTitle = "编辑";
      // console.log("编辑：",index,row);
      this.form.sm = row.SM;
      this.form.id = row.Id;
      // }else{
      //   ElMessage({
      //         message: "请选择个人",
      //         type: "warning",
      //       });
      // }
    },
    async que() {
      let me = this;
      me.form.bjid = this.$route.query.instanceId;
      if (me.dialogTitle == "新增") {
        await me.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatebzqksm",
            me.form
          )
          .then(function (response) {
            // console.log("99999999",response);
            if (response.data.IsSuccess) {
              ElMessage({
                message: "保存成功",
                type: "success",
              });
              me.form = {};
              me.whereconditions();
            } else {
              ElMessage({
                type: "error",
                message: response.data.ErrorMessage,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            ElMessage({
              type: "error",
              message: error,
            });
          });
      } else {
        await me.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatebzqksm",
            me.form
          )
          .then(function (response) {
            // console.log("99999999",response);
            if (response.data.IsSuccess) {
              ElMessage({
                message: "修改成功",
                type: "success",
              });
              me.form = {};
              me.whereconditions();
            } else {
              ElMessage({
                type: "error",
                message: response.data.ErrorMessage,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            ElMessage({
              type: "error",
              message: error,
            });
          });
      }
      this.dialogFormVisible = false;
    },
    //删除信息
    delNews(index, row) {
      ElMessageBox.confirm("是否删除此条消息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$ajax.get(
            this.$appConfig.apiUrls.ywxtApi +
              "/api/YW/deletebzqksm?id=" +
              row.Id
          );
          console.log("删除", res);
          if (res.data.IsSuccess) {
            ElMessage({
              type: "success",
              message: "操作成功!",
            });
            this.whereconditions();
          } else {
            ElMessage({
              type: "warning",
              message: "操作失败!",
            });
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  mounted() {
    this.whereconditions();
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
:deep(.el-divider--horizontal) {
  margin: 14px 0;
}
</style>
